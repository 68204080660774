<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label
          v-text="'Ferdi Katılma İstekleri'"
          class="mr-2 text-white"
        ></label>
        <b-button :to="{
            name: 'FederationGroups',
            params: { id: $route.params.id },
          }"
          variant="primary"
          class="ml-4"
        >
          Yarışma Grupları
        </b-button>
      </template>

      <div class="mt-4">
        <PMSTable
          :tableItems="getParsedIndividualGroups"
          :nameWithSurname="true"
          hasAction="personel"
          :erasable="true"
          :tableFields="fields"
          deleteMessage="İsteği silmek istediğinizden emin misiniz?"
          @multipleDeleteAction="deleteMultipleRequest"
          @multipleApproveAction="approveMultipleRequest"
          :limit="10000"
          :infinitePageItems="true"
          :actionModes="['Sil', 'Onayla']"
        ></PMSTable>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import PMSTable from "../../../components/tables/GeneralTable.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PMSTable,
  },
  computed: {
    ...mapGetters(["getIndividualGroups", "getManagementItems"]),
    getParsedIndividualGroups() {
      return this.getIndividualGroups.map((item) => {
        return {
          id: item.id,
          personels: [
            item.personel1,
            item.personel2,
            item.personel3,
            item.personel4,
            item.personelBackup,
            item.personelBackup2,
          ].filter((n) => n != null),
          category: this.getManagementItems?.find((x) => x.id == item.category)
            .title ?? "",
          boatType: this.getManagementItems?.find((x) => x.id == item.boatType)
            .title ?? "",
          distance: this.getManagementItems?.find((x) => x.id == item.distance)
            .title ?? "",

          events: item.events,
        };
      });
    },
  },
  data() {
    return {
      fields: [
        { key: "id", label: "Sıra No" },
        { key: "names", label: "Personeller" },
        { key: "category", label: "Kategori" },
        { key: "boatType", label: "Tekne Sınıfı" },
        { key: "distance", label: "Mesafe" },
        { key: "events", label: "İşlemler" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(
      "getIndividualGroupsByCompetitionId",
      this.$route.params.id
    );
  },
  methods: {
    deleteMultipleRequest(ids) {
      console.log(ids);
      this.$store.dispatch("deleteMultipleIndividualGroups", {
        ids: ids,
        competitionId: this.$route.params.id,
      }).then(() => {
        this.$store
          .dispatch("notification/setNotifications", [
            {
              title: "Başarılı!",
              body: "İstekler başarıyla silindi",
              type: "success",
            },
          ])
      });
    },
    approveMultipleRequest(ids) {
      console.log(ids);
      this.$store
        .dispatch("approveMultipleIndividualGroups", {
          ids: ids,
          competitionId: this.$route.params.id,
        })
        .then(() => {
          this.$store
            .dispatch("notification/setNotifications", [
              {
                title: "Başarılı!",
                body: "İstekler başarıyla onaylandı",
                type: "success",
              },
            ])
         
        });
    },
  },
};
</script>

<style>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>